(function() {
  'use strict';
  angular
    .module('app')
    // .constant('BASE_URL', 'http://localhost:3000')
    // .constant('DOMAIN_URL', 'http://localhost:3003')
    .constant('BASE_URL', 'https://callcig.cruzinsurancegroup.com')
    .constant('DOMAIN_URL', 'https://callcig.cruzinsurancegroup.com')
    .constant('swal', swal)
    .constant('_', _);
})();
